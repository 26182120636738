input,
button,
select,
.vs-select__control {
    border-radius: 35px !important;
}

input {}

.vs-country-input {
    gap: .3rem;
}

.vs-country-code input {
    text-align: center;
}

.light .vs-select__control {
    background-color: #fff !important;
}

.light .vs-search input {
    background-color: var(--background-d2) !important;
}

.light .vs-search-and-filter input {
    background-color: #fff !important;

}

.light .vs-explore-projects-btns {
    background-color: var(--primary-d5);
    color: var(--gray-d1);
}

.light .vs-submit-btn,
.light .vs-apply-btn,
.light .vs-sub-settings-submit-button,
.light .vs-login-register-btn {
    background-color: var(--primary-d5) !important;
    color: var(--gray-d1) !important;
}

.light .vs-explore-projects-btns svg .pathOne,
.light .vs-explore-projects-btns svg .pathTwo {
    stroke: var(--gray-d1) !important;
}

.light .vs-input-small svg .pathOne,
.light .vs-input-small svg .pathTwo,
.vs-email-verification .pathOne {
    fill: var(--primary-d5) !important;
    stroke: none !important
}

.light .vs-input svg .pathOne,
.light .vs-input svg .pathTwo {
    fill: var(--primary-d5) !important;
    stroke: none !important
}

.single_icon {}

.light .single_icon {
    fill: #07131c !important;
}

.map-icon * {
    stroke: var(--primary-d5) !important;
    stroke-width: 8;
}

body[dir="rtl"] button,
body[dir="rtl"] input {
    font-family: "Almarai" !important;
}

.big_icon * {
    fill: none !important;
    stroke: var(--primary-d5) !important;
    stroke-width: 7;
}

.light .big_icon * {
    stroke: var(--gray-d1) !important;
}

.dark .vs-icon-and-name .vs-feature-icon img {
    filter: brightness(0) saturate(100%) invert(67%) sepia(97%) saturate(400%) hue-rotate(100deg) brightness(96%) contrast(93%);
}

.light .vs-icon-and-name .vs-feature-icon img {
    filter: brightness(0) saturate(100%) invert(7%) sepia(8%) saturate(3503%) hue-rotate(164deg) brightness(93%) contrast(100%);
}